
import { defineComponent } from "vue";
import {transactionhistory}  from "@/api/transaction";

export default defineComponent({
  name: "kt-widget-11", 
  methods: {
    async transactionhistory(){ 
      this.loading = true;
        const response = await transactionhistory(1);
        this.list = response.data.pageList;
        this.loading = false;
    }
  },
  mounted(){
      this.transactionhistory();
  },
  data(){
      return { 
        list : [],
        loading: true,
      }
  },
  components: {},
  props: {
    widgetClasses: String
  }
});
